import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function PlaceholderImage({ alt = "" }) {
  return (
    <StaticImage
      placeholder="blurred"
      className="img-fluid rounded"
      src="../images/placeholder-logo.jpg"
      alt={alt}
    />
  )
}
