import React from "react"
import { graphql, Link } from "gatsby"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ListGroup from "react-bootstrap/ListGroup"
import Layout from "../components/Layout"
import ConvertTimestamp from "../components/ConvertTimestamp"
import {
  Calendar3,
  Geo,
  Whatsapp,
  TwitterX,
  Facebook,
  Pencil,
} from "react-bootstrap-icons"
import PlaceholderImage from "../components/PlaceholderImage"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/Seo"
import { ExternalLink } from "../components/ui/ExternalLink"

export const Head = ({ data }) => {
  const { abstract, author, title } = data.markdownRemark.frontmatter
  return (
    <Seo
      customTitle={title}
      customAuthor={author ? author : undefined}
      customDescription={abstract}
    />
  )
}

export default function articleDetails({ data }) {
  const { html } = data.markdownRemark
  const {
    title,
    subTitle,
    abstract,
    release,
    location,
    img,
    category,
    author,
  } = data.markdownRemark.frontmatter

  const image = getImage(img)

  let link = ""

  // Fix: error "window" is not available during server side rendering

  // Some `browser globals` like window or document that aren't
  // available in Node.js. If this is your problem you should see an
  // error above like 'window is not defined'. To fix this, find the
  // offending code and check before calling the code if window is
  // defined so the code doesn't run while Gatsby is building

  // https://www.gatsbyjs.com/docs/debugging-html-builds/

  const isBrowser = typeof window !== "undefined"

  if (isBrowser) {
    link = window.location
  }

  const socialLinks = [
    <ExternalLink
      href={`https://api.whatsapp.com/send/?phone&text=${link}&app_absent=0`}
    >
      <Whatsapp className="mr-2" style={{ color: "#25D366" }} />
    </ExternalLink>,
    <ExternalLink href={`https://x.com/intent/post?url=${link}`}>
      <TwitterX className="mr-2" style={{ color: "#14171A" }} />
    </ExternalLink>,
    <ExternalLink href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}>
      <Facebook className="mr-2" style={{ color: "#1877F2" }} />
    </ExternalLink>,
  ]

  const breadcrumbLinks = [
    {
      to: "/",
      label: "Hauptseite",
    },
    {
      to: "/archiv",
      label: "Archiv",
    },
  ]

  return (
    <Layout>
      <Container className="my-5">
        <header className="border-bottom mt-3">
          {image ? (
            <GatsbyImage image={image} className="rounded" alt={title} />
          ) : (
            <PlaceholderImage className="rounded" />
          )}
          <Breadcrumb className="my-3 d-none d-lg-block">
            {breadcrumbLinks.map(({ to, label }) => (
              <Breadcrumb.Item key={label} linkAs={Link} linkProps={{ to }}>
                {label}
              </Breadcrumb.Item>
            ))}
            <Breadcrumb.Item active>{title}</Breadcrumb.Item>
          </Breadcrumb>
          <h1 className="font-weight-bold display-4 text-break mt-3 mt-lg-0">
            {title}
          </h1>
          <h2 className="text-muted h4 mb-5">{subTitle}</h2>
        </header>
        <Row>
          <Col as="article" className="text-dark order-1">
            <p className="lead font-weight-bold my-5">{abstract}</p>
            <div
              className="lh-text"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </Col>
          <Col
            as="aside"
            lg={4}
            className="text-secondary mt-5 ml-lg-5 order-0 order-lg-1"
          >
            <p className="h5 font-weight-bold mb-3 text-dark">
              Über diesen Artikel
            </p>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <time className="d-flex align-items-center" datatime={release}>
                  <Calendar3 className="mr-2" />
                  <ConvertTimestamp date={release} lang="DE-de" />
                </time>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex align-items-center">
                <Geo className="mr-2" />
                {location}
              </ListGroup.Item>
              <ListGroup.Item className="d-flex align-items-center">
                <Pencil className="mr-2" />
                {author ? <span>{author}</span> : <span>Admin</span>}
              </ListGroup.Item>
              <ListGroup.Item>
                {category.map((entry, i) => (
                  <span className="badge" key={i}>
                    {entry}
                  </span>
                ))}
              </ListGroup.Item>
            </ListGroup>
            <div className="text-dark mt-5">
              <p className="h5 font-weight-bold mb-3">
                Teilen Sie diesen Artikel
              </p>
              <ul className="list-unstyled list-inline h2">
                {socialLinks.map((social, i) => (
                  <li className="list-inline-item" key={i}>
                    {social}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

// Variable must be the same as the context variable in `gatsby-node.js`
// $slug === context: { slug: node.frontmatter.slug }
export const query = graphql`
  query articleDetails($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subTitle
        release
        location
        img {
          childImageSharp {
            gatsbyImageData
          }
        }
        author
        abstract
        category
      }
    }
  }
`
