import React from "react"
import Timeago from "timeago-react"
import * as timeago from "timeago.js"
import de from "timeago.js/lib/lang/de"

export default function ConvertTimestamp({ date, lang }) {
  timeago.register("de", de)

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
  // 2-digit eg. `19.08.` instead of `19.8.`
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }

  const convertedDate = new Date(date).toLocaleDateString(lang, options)

  return (
    <abbr title={convertedDate}>
      Veröffentlicht <Timeago datetime={date} locale="de" />
    </abbr>
  )
}
